









import { defineComponent } from "@vue/composition-api";
import { Cell, CellGroup } from "vant";

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  }
});
